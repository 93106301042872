import * as Yup from "yup";
import { AddressLookup } from "components/form/AddressLookup";
import { AuthGuard } from "components/AuthGuard";
import { AutoComplete } from "components/form/AutoComplete";
import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import { COUNTRIES_LIST } from "./countries-list";
import { Divider } from "components/Divider";
import { DropdownInput, FormikTextField } from "components/form";
import { faClose, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FileUpload } from "components/FileUpload";
import { FlexColumnWrapper } from "components/FlexColumnWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikProvider } from "formik";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { NewTagComponent } from "components/NewTagComponent";
import { SectionHeading } from "components/Headings/SectionHeading";
import {
    selectAvailableSearchOptions,
    setAvailableOptions,
} from "store/reducers/searchReducer";
import { Toggle } from "components/form/Toggle";
import { uploadMedia } from "Context";
import {
    useCreateVacancyMutation,
    useGetAIJobDescriptionMutation,
    useGetCompanyQuery,
    useGetVacancyByIdQuery,
    useUpdateVacancyMutation,
} from "store/userAPI";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useGetSearchOptionsMutation } from "store/searchAPI";
import { useLogger } from "hooks/useLogger";
import { useNavigate, useParams } from "react-router-dom";
import { useNotifyCannotCreateVacanciesMutation } from "store/userAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

const validationSchema = Yup.object().shape({
    vacancy: Yup.object().shape({
        title: Yup.string().required("'Job Title' is required"),
        internalId: Yup.string(),
        industry: Yup.string().required("'Industry' is required"),
        country: Yup.string().required("'Country' is required"),
        address: Yup.string().required("Address' is required"),
        equityAvailable: Yup.boolean().required("'Equity' is required"),
        contractType: Yup.string().required("'Contract Type' is required"),
        directToCompany: Yup.string().required(
            "'Direct or Agency' is required"
        ),
        salaryRangeFrom: Yup.number()
            .moreThan(12000, "Salary Range From' must greater than 12000")
            .required("'Salary Range From' is required"),
        salaryRangeTo: Yup.number()
            .test(
                "lessThanParent",
                "'Salary Range To' must be less than double of salary range from",
                function (value) {
                    return this.parent.salaryRangeFrom * 2 >= value;
                }
            )
            .moreThan(
                12000,
                "'Salary Range To' value must be greater than 12000"
            )
            .required("'Salary Range To' is required"),

        bonusRangeFrom: Yup.number().moreThan(
            1000,
            "'Bonus Range From' must greater than 1000"
        ),
        bonusRangeTo: Yup.number().moreThan(
            Yup.ref("bonusRangeFrom"),
            "'Bonus Range To' to should be greater than Bonus range from"
        ),
        jobDescription: Yup.string()
            .required("'Job Description' is required")
            .max(
                1600,
                "'Job Description' must be less than 1600 characters"
            ),
        isPublic: Yup.boolean().required("'Public Visibility' is required"),
    }),
});

export const CreateVacancy = React.memo(() => {
    const { data: company = {}, error, isLoading } = useGetCompanyQuery({});

    const { logger } = useLogger();
    const navigate = useNavigate();

    const size = useResponsiveLayout();
    const dispatch = useDispatch();

    const [tags, setTags] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [candidateQuestions, setCandidateQuestions] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState();
    const [validationInfo, setValidationInfo] = useState({});
    const [selectedFile, setSelectedFile] = useState();
    const [isUploading, setIsUploading] = useState(false);

    const { vacancyId } = useParams();

    let [showBonus, setShowBonus] = useState(false);

    const [createVacancy] = useCreateVacancyMutation();
    const [updateVacancy] = useUpdateVacancyMutation();
    const [getAIJobDescription] = useGetAIJobDescriptionMutation();
    const { data: existingVacancy } = useGetVacancyByIdQuery(
        { id: vacancyId },
        { skip: !vacancyId }
    );

    // ******** AI job description bits

    // Is the AI prompt visible?
    const [showAIPrompt, setShowAIPrompt] = useState(false);

    // Has an AI prompt been received?
    const [hasAIJobDescription, setHasAIJobDescription] = useState(false);

    // The original Job Description as typed by the user
    const [originalJobDescription, setOriginalJobDescription] = useState();

    // Track loading state
    const [isAIJobDescriptionLoading, setIsAIJobDescriptionLoading] =
        useState(false);

    // Whether the user has opted to use AI tools to generate a job description
    const [useAI, setUseAI] = useState(false);

    // Which AI generation option the user has selected
    const [AIOption, setAIOption] = useState("condense");

    // Job descirption input field
    const jobDescRef = useRef();
    // ********  End of AI job description bits

    // ******** Tips - intersection observers
    const [showInfoVideo, setShowInfoVideo] = useState(false);
    const [currentTip, setCurrentTip] = useState("Tell me about your job");

    const intersectionObserverRef = useRef();
    const jobDetailsIntersectionRef = useRef();
    const jobDescriptionIntersectionRef = useRef();
    const skillsIntersectionRef = useRef();
    const benefitsIntersectionRef = useRef();
    const questionsIntersectionRef = useRef();

    useEffect(() => {
        if (company.id) {
            intersectionObserverRef.current = new IntersectionObserver(
                (e) => {
                    e.forEach((entry, i) => {
                        if (entry.isIntersecting) {
                            const tip = entry.target.attributes["data-tip"].value;
                            setCurrentTip(tip);
                        }
                    });
                },
                {
                    rootMargin: "0px 0px -50% 0px",
                    threshold: 0,
                }
            );

        }

    }, [company.id]);

    useEffect(() => {
        if (company.id && intersectionObserverRef.current) {
            jobDetailsIntersectionRef.current && intersectionObserverRef.current.observe(
                jobDetailsIntersectionRef.current
            );
            jobDescriptionIntersectionRef.current && intersectionObserverRef.current.observe(
                jobDescriptionIntersectionRef.current
            );
            skillsIntersectionRef.current && intersectionObserverRef.current.observe(
                skillsIntersectionRef.current
            );
            benefitsIntersectionRef.current && intersectionObserverRef.current.observe(
                benefitsIntersectionRef.current
            );
            questionsIntersectionRef.current && intersectionObserverRef.current.observe(
                questionsIntersectionRef.current
            );

            return () => {
                intersectionObserverRef.current.disconnect();
            };
        }
    }, [company.id, intersectionObserverRef]);
    // ********  End of Tips - intersection observers

    /** Input options */
    const availableOptions = useSelector(selectAvailableSearchOptions);
    const [getSearchOptions] = useGetSearchOptionsMutation();
    const [notifyCannotCreateVacancies] =
        useNotifyCannotCreateVacanciesMutation();
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);

    const [isLocked, setIsLocked] = useState(false);

    /**
     * Get available search inputs on load, if required
     */
    const staticOptions = JSON.stringify(availableOptions)
    useEffect(() => {
        const work = async () => {
            if (isLoadingOptions === false && (!availableOptions || availableOptions.length === 0)) {
                setIsLoadingOptions(true);
                const res = await getSearchOptions();
                setIsLoadingOptions(false);
                dispatch(setAvailableOptions(res.data));
            }
        };

        if (company.id) work().finally();
    }, [company.id, isLoadingOptions, staticOptions]);
    /********** End of input options */

    useEffect(() => {
        // Set the company benfits on the initial load of the company details
        if (company.id && company.benefits && benefits.length == 0) {
            setBenefits(company.benefits);
        }
    }, [benefits.length, company.id]);



    useEffect(() => {
        if (company.id && !!existingVacancy) {
            setBenefits(existingVacancy?.benefits || []);
            setTags(existingVacancy?.tags || []);
            setCandidateQuestions(existingVacancy?.questions || []);
            setSelectedVideo(existingVacancy?.videoURL);

            console.log("Setting file", existingVacancy?.portfolio);
            setSelectedFile(existingVacancy?.portfolio);
        }
    }, [existingVacancy]);

    const uploadFile = async () => {
        setIsUploading(true);
        let data = new FormData();
        data.append("file", selectedFile);
        try {
            let response = await uploadMedia(data);
            setIsUploading(false);
            if (!response) return alert("An error has occured.");
            if (response.error) return alert(response?.message);
            return response?.url;
        } catch (error) {
            console.log(error);
            setIsUploading(false);
        }
    };

    const onSubmit = async (goToVideo) => {
        if (isLocked) return;

        let fileURL;
        if (selectedFile) {
            fileURL = await uploadFile();
        }

        const payload = {
            title: values.vacancy.title,
            internalId: values.vacancy.internalId,
            description: values.vacancy.jobDescription,
            industry: values.vacancy.industry,
            address: values.vacancy.address,
            country: values.vacancy.country,
            equityAvailable: values.vacancy.equityAvailable,
            contractType: values.vacancy.contractType,
            directToCompany: values.vacancy.directToCompany,
            salary: {
                start: +values.vacancy.salaryRangeFrom,
                end: +values.vacancy.salaryRangeTo,
            },
            bonus: {
                start: +values.vacancy.bonusRangeFrom,
                end: +values.vacancy.bonusRangeTo,
            },
            tags: tags,
            benefits: benefits,
            questions: candidateQuestions,
            remoteWorking: values.vacancy.remoteWorking,
            videoURL: selectedVideo,
            portfolio: fileURL || existingVacancy?.portfolio,
            isPublic: values.vacancy.isPublic,
        };

        try {
            if (!!vacancyId) {
                const response = await updateVacancy({
                    vacancyId: { vacancyId },
                    payload,
                });
                if (!response.data) return;

                // If complete, go to preview or record video, else stay here and update the vacancy ID
                // The form has not yet been validated, do this now
                await validate();

                if (
                    goToVideo ||
                    (isValid &&
                        Object.keys(validationInfo).length === 0 &&
                        !existingVacancy.videoURL)
                ) {
                    navigate(
                        `/employer/company/vacancy/edit/${response?.data?.id}/select-video-source`
                    );
                } else if (
                    isValid &&
                    Object.keys(validationInfo).length === 0 &&
                    existingVacancy.videoURL
                ) {
                    navigate(
                        `/employer/company/preview-vacancy/${response?.data?.id}`
                    );
                } else {
                    navigate(
                        `/employer/company/vacancy/edit/${response?.data?.id}`
                    );
                }
            } else {
                const response = await createVacancy(payload);
                if (!response.data) return;
                if (response.error) return alert(response.message);
                else {
                    // If complete, go to record, else stay here and update the vacancy ID
                    // The form has not yet been validated, do this now
                    await validate();
                    if (isValid && Object.keys(validationInfo).length === 0) {
                        navigate(
                            `/employer/vacancy/create/videorecorder/${response?.data?.id}`
                        );
                    } else {
                        navigate(
                            `/employer/company/vacancy/edit/${response?.data?.id}`
                        );
                    }
                }
            }
        } catch (error) {
            console.log("onSubmit()", error);
        }
    };



    const formikbag = useFormik({
        initialValues: {
            vacancy: {
                title: existingVacancy?.title || "",
                internalId: existingVacancy?.internalId || "",
                industry: existingVacancy?.industry || "",
                country: existingVacancy?.country || "United Kingdom",
                address: existingVacancy?.address
                    ? existingVacancy?.address
                    : company?.primaryAddress || "",
                equityAvailable: existingVacancy?.equityAvailable || false,
                contractType: existingVacancy?.contractType || "",
                salaryRangeFrom: existingVacancy?.salary?.start || 0,
                salaryRangeTo: existingVacancy?.salary?.end || 0,
                bonusRangeFrom: existingVacancy?.bonus?.start || "",
                bonusRangeTo: existingVacancy?.bonus?.end || "",
                jobDescription: existingVacancy?.description || "",
                tag: "",
                benefit: "",
                candidateQuestion: "",
                remoteWorking: existingVacancy?.remoteWorking || "",
                portfolio: existingVacancy?.portfolio || "",
                isPublic: existingVacancy?.isPublic === false ? false : true,
            },
        },
        enableReinitialize: true,
        onSubmit,
        validationSchema,
        validateOnMount: false,
    });
    const { values, setFieldValue, validateForm, isValid, errors } = formikbag;

    const {
        vacancy: { tag, benefit, candidateQuestion },
    } = values;

    const addValue = (field, array, value) => {
        try {
            if (field === "vacancy.tag") {
                setTags([...array, value]);
            } else if (field === "vacancy.benefit") {
                setBenefits([...array, value]);
            } else if (field === "vacancy.candidateQuestion") {
                setCandidateQuestions([...array, value]);
            }
            setFieldValue(field, "");
        } catch (error) {
            console.log("addValue()", error);
        }
    };

    const removeValue = (field, array, value) => {
        if (field === "vacancy.tag") {
            setTags(array.filter((i) => i !== value));
        } else if (field === "vacancy.benefit") {
            setBenefits(array.filter((i) => i !== value));
        } else if (field === "vacancy.candidateQuestion") {
            setCandidateQuestions(array.filter((i) => i !== value));
        }
    };

    /** Validates both the Formik form and custom elements like benefits, skills, and questions */
    const validate = useCallback(async () => {
        validateForm();

        // Validate skills, benefits, and questions
        let _validationInfo = {};
        if (tags.length <= 0) {
            _validationInfo.tags = "At least 1 skill is required";
        }

        if (benefits.length <= 0) {
            _validationInfo.benefits = "At least 1 benefit is required";
        }

        if (candidateQuestions.length <= 0) {
            _validationInfo.candidateQuestions =
                "At least 1 question is required";
        }

        setValidationInfo(_validationInfo);
    }, [benefits, candidateQuestions, tags, validateForm]);

    /**
     * The click events on dropdown init sets the field on load
     * Formik has these fields set just fine, but has NOT validated them
     * This is because the dropdowns are not an actual inputs and therefore have no blur etc
     * Easy solution is to just trigger a validate whenever the values change
     */
    useEffect(() => {
        if (company.id) validate();
    }, [validate, values]);

    /**
     * Reacts to input in the Job Description field and notifies the user of the AI feature
     * Debounced so that the user must have stopped typing for 3 seconds before firing
     * @param value the value that was typed
     */
    const onJobDescriptionChange = (value) => {
        setShowAIPrompt(false);
        setHasAIJobDescription(false);

        if (value.length > 80) {
            setShowAIPrompt(true);
        }
    };

    const onGetAIJobDescription = async (value) => {
        logger.info(`Getting AI text for Job Description`, {
            type: "AIJobDescription",
            prompt: AIOption,
        });

        // Keep the existing entry in state so that the user can revert
        setOriginalJobDescription(value);

        setIsAIJobDescriptionLoading(true);

        try {
            const res = await getAIJobDescription({
                input: value,
                type: AIOption,
            });
            const newJobDescription = res.data.text;
            const formatted = newJobDescription.replace(/^\s+|\s+$/g, "");
            setHasAIJobDescription(true);

            setFieldValue(
                "vacancy.jobDescription",
                // Response sometimes comes back with line breaks at the start. Trim any out.
                formatted
                // newJobDescription.replace(/^\s+|\s+$/g, ''),
            );
        } catch (ex) {
            alert("Sorry, something went wrong, please try again later.");
        } finally {
            setIsAIJobDescriptionLoading(false);
        }
    };

    const selectFileUploadPortfolio = (file) => {
        setSelectedFile(file);
    };

    /**
     * Guard to disable ability to create vacancies
     * Don't use AuthGuard - the user would have to log out/in to get company updates and Seesy's JWTs are indefinite
     */
    useEffect(() => {
        const work = async () => {
            setIsLocked(true);
            logger.info(
                `Checking whether to send notification to company ${company.id}`,
                { type: `CreateVacancy` }
            );
            if (!company.isCreateVacanciesEmailSent) {
                // Send the email
                logger.info(
                    `Sending locked company alert notification to Customer support re company ${company.id}`,
                    { type: `CreateVacancy` }
                );
                await notifyCannotCreateVacancies({ id: company.id });
            } else {
                logger.info(
                    `Locked company alert notification has already been sent re company ${company.id}`,
                    { type: `CreateVacancy` }
                );
            }
        };
        if (company.id && !isLocked && !company.canCreateVacancies) work().finally();
    }, [company, isLocked, notifyCannotCreateVacancies]);


    if (error) return <p>Something went wrong</p>
    if (isLoading) return <div>....Loading</div>
    return (
        <AuthGuard
            criteria={[
                { value: "EMPLOYER", check: "userDetails.userType" },
                existingVacancy && {
                    value: existingVacancy.companyId,
                    check: "membership.companyId",
                },
            ]}
        >
            <div>
                {isLocked && (
                    <Modal
                        actions={[{ text: "Ok", onClick: () => navigate('/') }]}
                        title="You can't create new Vacancies"
                    >
                        <p>
                            {company.name} does not have the ability to create
                            Vacancies.
                        </p>
                        <p className="font-light">
                            Customer Support have been notified and will be in
                            touch shortly to enable vacancies for your company.
                        </p>
                    </Modal>
                )}
                <Container size={size}>
                    <SectionHeading
                        companyName
                        title={`${!!vacancyId ? "edit" : "create"} a vacancy`}
                    />
                    <hr />
                    <ContentWrapper size={size}>
                        <div className="max-w-screen-md">
                            <FormikProvider value={formikbag}>
                                <div className="grid gap-16">
                                    <section
                                        className="grid gap-16"
                                        ref={jobDetailsIntersectionRef}
                                        data-tip="Tell me about your job"
                                    >
                                        <FormikTextField
                                            placeholder="Job Title"
                                            fullWidth
                                            name="vacancy.title"
                                            label="Job Title"
                                        />
                                        <FormikTextField
                                            placeholder="Internal ID"
                                            fullWidth
                                            name="vacancy.internalId"
                                            label="Internal ID"
                                        />
                                        <DropdownInput
                                            useFirstOption={true}
                                            name="vacancy.directToCompany"
                                            options={availableOptions?.directToCompany?.map(
                                                (o) => o.name
                                            )}
                                            isLoading={isLoadingOptions}
                                            label={"Direct or Agency"}
                                            onClick={(selectedValue) =>
                                                setFieldValue(
                                                    "vacancy.directToCompany",
                                                    selectedValue
                                                )
                                            }
                                            value={
                                                values.vacancy.directToCompany
                                            }
                                        />
                                        <DropdownInput
                                            useFirstOption={true}
                                            name="vacancy.contractType"
                                            options={availableOptions?.contractType?.map(
                                                (o) => o.name
                                            )}
                                            isLoading={isLoadingOptions}
                                            label={"Contract Type"}
                                            onClick={(selectedValue) =>
                                                setFieldValue(
                                                    "vacancy.contractType",
                                                    selectedValue
                                                )
                                            }
                                            value={values.vacancy.contractType}
                                        />
                                        <DropdownInput
                                            useFirstOption={true}
                                            name="vacancy.remoteWorking"
                                            options={availableOptions?.remoteWorking?.map(
                                                (o) => o.name
                                            )}
                                            isLoading={isLoadingOptions}
                                            label={"Working Environment"}
                                            onClick={(selectedValue) =>
                                                setFieldValue(
                                                    "vacancy.remoteWorking",
                                                    selectedValue
                                                )
                                            }
                                            value={values.vacancy.remoteWorking}
                                        />
                                        <DropdownInput
                                            useFirstOption={true}
                                            name="vacancy.industry"
                                            options={availableOptions?.industry?.map(
                                                (o) => o.name
                                            )}
                                            isLoading={isLoadingOptions}
                                            label={"Industry"}
                                            onClick={(selectedValue) =>
                                                setFieldValue(
                                                    "vacancy.industry",
                                                    selectedValue
                                                )
                                            }
                                            value={values.vacancy.industry}
                                        />
                                        <AutoComplete
                                            style={{ marginBottom: "30px" }}
                                            placeholder={"Select a country"}
                                            name="vacancy.country"
                                            data={COUNTRIES_LIST.map(
                                                (o) => o.name
                                            )}
                                            label={"Country"}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "vacancy.country",
                                                    value
                                                );
                                            }}
                                            value={values.vacancy.country}
                                        />
                                        <AddressLookup
                                            className="w-full"
                                            id="addressLookup"
                                            label="Address"
                                            onResult={(result) => {
                                                setFieldValue(
                                                    "vacancy.address",
                                                    result
                                                );
                                            }}
                                            value={values.vacancy?.address}
                                        />
                                        <div className="grid gap-6">
                                            <label
                                                className="text-white"
                                                htmlFor={"salaryRangeFrom"}
                                            >
                                                Salary
                                            </label>
                                            <div className="grid grid-cols-[1fr_20px_1fr] items-start gap-6">
                                                <FormikTextField
                                                    placeholder="From"
                                                    fullWidth
                                                    id="salaryRangeFrom"
                                                    name="vacancy.salaryRangeFrom"
                                                    symbol="£"
                                                    onChange={(e) => {
                                                        e.target &&
                                                            setFieldValue(
                                                                "vacancy.salaryRangeFrom",
                                                                e.target.value
                                                            );
                                                    }}
                                                />
                                                <div className="h-1 w-full bg-white mt-12"></div>
                                                <FormikTextField
                                                    placeholder="To"
                                                    fullWidth
                                                    name="vacancy.salaryRangeTo"
                                                    symbol="£"
                                                    onChange={(e) => {
                                                        e.target &&
                                                            setFieldValue(
                                                                "vacancy.salaryRangeTo",
                                                                e.target.value
                                                            );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {showBonus ? (
                                            <div className="grid gap-6">
                                                <label
                                                    className="text-white"
                                                    htmlFor={"bonusRangeFrom"}
                                                >
                                                    Bonus Range
                                                </label>
                                                <div className="grid grid-cols-[1fr_20px_1fr] items-start gap-6">
                                                    <FormikTextField
                                                        placeholder="From"
                                                        fullWidth
                                                        id="bonusRangeFrom"
                                                        name="vacancy.bonusRangeFrom"
                                                        symbol="£"
                                                    />
                                                    <div className="h-1 w-full bg-white mt-12"></div>
                                                    <FormikTextField
                                                        placeholder="To"
                                                        fullWidth
                                                        name="vacancy.bonusRangeTo"
                                                        symbol="£"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                className="group text-white flex gap-6 flex-row hover:bg-primary hover:text-black rounded-xl justify-self-start"
                                                onClick={() =>
                                                    setShowBonus(true)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="w-10 h-10 text-primary group-hover:text-black"
                                                />
                                                Add bonus or commission
                                            </button>
                                        )}

                                        <div className="grid gap-4">
                                            <label
                                                htmlFor="equity"
                                                className="text-white"
                                            >
                                                Equity
                                            </label>
                                            <button
                                                onClick={() =>
                                                    setFieldValue(
                                                        "vacancy.equityAvailable",
                                                        !values.vacancy
                                                            .equityAvailable
                                                    )
                                                }
                                                className="flex gap-12 p-8 bg-[#232424] text-white rounded-xl justify-between"
                                            >
                                                <p>
                                                    Equity
                                                    {values.vacancy
                                                        .equityAvailable
                                                        ? " available"
                                                        : " unavailable"}
                                                </p>
                                                <Toggle
                                                    checked={
                                                        values.vacancy
                                                            .equityAvailable
                                                    }
                                                    onChange={() =>
                                                        setFieldValue(
                                                            "vacancy.equityAvailable",
                                                            !values.vacancy
                                                                .equityAvailable
                                                        )
                                                    }
                                                    name="Equity Available"
                                                    inactiveBackground={
                                                        size.isSmUp
                                                            ? "black"
                                                            : null
                                                    }
                                                />
                                            </button>
                                        </div>
                                    </section>
                                    <Divider />
                                    <section
                                        ref={jobDescriptionIntersectionRef}
                                        data-tip="Copy & paste your JD"
                                        className="grid gap-6 mb-[30px]"
                                    >
                                        <label
                                            className="text-white"
                                            htmlFor={jobDescRef}
                                        >
                                            Detailed job description (200 words)
                                        </label>

                                        <div className="grid gap-8 p-8 bg-[#232424] text-white rounded-xl">
                                            <button
                                                onClick={() => setUseAI(!useAI)}
                                                className="flex gap-12 justify-between items-center text-left p-0"
                                            >
                                                <p>
                                                    Use AI to generate a job
                                                    description
                                                </p>
                                                <Toggle
                                                    checked={useAI}
                                                    onChange={() =>
                                                        setUseAI(!useAI)
                                                    }
                                                    name="Public"
                                                    inactiveBackground="black"
                                                />
                                            </button>
                                            {useAI && (
                                                <ul class="grid w-full gap-6 lg:grid-cols-2 items-stretch">
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            id="aiOption-condense"
                                                            name="aiOption"
                                                            value="condense"
                                                            class="hidden peer"
                                                            checked={
                                                                AIOption ===
                                                                "condense"
                                                            }
                                                            onChange={(e) =>
                                                                setAIOption(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="aiOption-condense"
                                                            class="grid h-full items-start w-full px-6 py-4 text-gray-300 bg-[#1c1d1d] border border-[#1c1d1d] rounded-lg cursor-pointer peer-checked:border-primary peer-checked:bg-primary peer-checked:text-black hover:text-white hover:border-primary transition-all"
                                                        >
                                                            <div class="grid gap-2">
                                                                <div class="w-full text-[16px]">
                                                                    Use an
                                                                    existing
                                                                    description
                                                                </div>
                                                                <div class="w-full text-xl font-semibold">
                                                                    Provide an
                                                                    existing job
                                                                    description
                                                                    to use as a
                                                                    base.
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="radio"
                                                            id="aiOption-keywords"
                                                            name="aiOption"
                                                            value="keywords"
                                                            class="hidden peer"
                                                            checked={
                                                                AIOption ===
                                                                "keywords"
                                                            }
                                                            onChange={(e) =>
                                                                setAIOption(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="aiOption-keywords"
                                                            class="grid h-full items-start w-full px-6 py-4 text-gray-300 bg-[#1c1d1d] border border-[#1c1d1d] rounded-lg cursor-pointer peer-checked:border-primary peer-checked:bg-primary peer-checked:text-black hover:text-white hover:border-primary transition-all"
                                                        >
                                                            <div class="grid gap-2">
                                                                <div class="w-full text-[16px]">
                                                                    Provide some
                                                                    keywords
                                                                </div>
                                                                <div class="w-full text-xl font-semibold">
                                                                    Use a set of
                                                                    keywords to
                                                                    generate a
                                                                    job
                                                                    description.
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                        {!useAI && (
                                            <p className="text-[16px] text-gray-300 font-thin">
                                                <strong>Tip:</strong> We can
                                                generate a job description from
                                                your input using AI! You can
                                                either provide an entire job
                                                description for us to use, or a
                                                set of keywrods. Would you like
                                                to try?
                                            </p>
                                        )}

                                        <FormikTextField
                                            ref={jobDescRef}
                                            placeholder="Detailed job description"
                                            fullWidth
                                            type="textArea"
                                            name="vacancy.jobDescription"
                                            onChange={({ value }) =>
                                                onJobDescriptionChange(value)
                                            }
                                            style={{
                                                borderBottomLeftRadius:
                                                    showAIPrompt ? "0" : "5px",
                                                borderBottomRightRadius:
                                                    showAIPrompt ? "0" : "5px",
                                                minHeight: "200px",
                                            }}
                                            disabled={isAIJobDescriptionLoading}
                                        />

                                        {showAIPrompt && useAI && (
                                            <AIPrompt>
                                                {!hasAIJobDescription && (
                                                    <p>
                                                        When you're ready, click
                                                        the button below to
                                                        generate a job
                                                        description.
                                                    </p>
                                                )}
                                                {hasAIJobDescription && (
                                                    <p>
                                                        Great! We’ve replaced
                                                        your description with
                                                        the AI-generated one.
                                                        Would you like to use
                                                        it, or go back to your
                                                        own?
                                                    </p>
                                                )}
                                                <div>
                                                    <Button
                                                        button
                                                        children={
                                                            isAIJobDescriptionLoading ? (
                                                                <Icon
                                                                    icon={
                                                                        faSpinner
                                                                    }
                                                                    spin
                                                                />
                                                            ) : hasAIJobDescription ? (
                                                                "Keep it"
                                                            ) : (
                                                                `Let's see!`
                                                            )
                                                        }
                                                        style={{
                                                            opacity:
                                                                isAIJobDescriptionLoading
                                                                    ? "0.66"
                                                                    : "1",
                                                        }}
                                                        onClick={() =>
                                                            !isAIJobDescriptionLoading
                                                                ? hasAIJobDescription
                                                                    ? (() => {
                                                                        // Keep it
                                                                        setShowAIPrompt(
                                                                            false
                                                                        );
                                                                        setHasAIJobDescription(
                                                                            false
                                                                        );
                                                                    })()
                                                                    : // "Let's see"
                                                                    onGetAIJobDescription(
                                                                        values
                                                                            .vacancy
                                                                            .jobDescription
                                                                    )
                                                                : null
                                                        }
                                                    />
                                                    {hasAIJobDescription && (
                                                        <Button
                                                            button
                                                            type="alt"
                                                            children="Discard"
                                                            style={{
                                                                opacity:
                                                                    isAIJobDescriptionLoading
                                                                        ? "0.66"
                                                                        : "1",
                                                            }}
                                                            onClick={() => {
                                                                setShowAIPrompt(
                                                                    false
                                                                );
                                                                setHasAIJobDescription(
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "vacancy.jobDescription",
                                                                    originalJobDescription
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </AIPrompt>
                                        )}
                                    </section>
                                    <Divider />
                                    <section
                                        ref={skillsIntersectionRef}
                                        data-tip="Skills like sales, empathy & innovation"
                                        className="grid gap-12"
                                    >
                                        <div className="grid gap-4">
                                            <label
                                                htmlFor="addTag"
                                                className="text-white"
                                            >
                                                Hard & Soft Skills
                                            </label>
                                            <div className="grid gap-6 grid-cols-[1fr_auto] items-stretch">
                                                <FormikTextField
                                                    id="addTag"
                                                    placeholder="Add Tag"
                                                    fullWidth
                                                    spacer={false}
                                                    name="vacancy.tag"
                                                    value={tag}
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === "Enter" &&
                                                            values.vacancy.tag
                                                                ?.length > 0
                                                        ) {
                                                            addValue(
                                                                "vacancy.tag",
                                                                tags,
                                                                tag
                                                            );
                                                        }
                                                    }}
                                                />

                                                <Button
                                                    button
                                                    children={"Add"}
                                                    disabled={
                                                        values.vacancy?.tag
                                                            ?.length < 1
                                                    }
                                                    onClick={() => {
                                                        addValue(
                                                            "vacancy.tag",
                                                            tags,
                                                            tag
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {tags && tags.length > 0 && (
                                            <FlexColumnWrapper className="mb-12 -mt-4">
                                                {tags.map((tag, index) => {
                                                    return (
                                                        <NewTagComponent
                                                            key={index}
                                                            onClick={() =>
                                                                removeValue(
                                                                    "vacancy.tag",
                                                                    tags,
                                                                    tag
                                                                )
                                                            }
                                                        >
                                                            {tag}
                                                        </NewTagComponent>
                                                    );
                                                })}
                                            </FlexColumnWrapper>
                                        )}
                                    </section>
                                    <Divider />
                                    <section
                                        ref={benefitsIntersectionRef}
                                        data-tip="Well being, gym membership, ride to work..."
                                    >
                                        <div className="grid gap-4">
                                            <label
                                                htmlFor="addBenefit"
                                                className="text-white"
                                            >
                                                Benefits of working with us
                                            </label>
                                            <div className="grid gap-6 grid-cols-[1fr_auto] items-stretch">
                                                <FormikTextField
                                                    id="addBenefit"
                                                    placeholder="Type your benefit"
                                                    fullWidth
                                                    name="vacancy.benefit"
                                                    value={benefit}
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === "Enter" &&
                                                            values?.vacancy
                                                                ?.benefit
                                                                ?.length > 0
                                                        ) {
                                                            addValue(
                                                                "vacancy.benefit",
                                                                benefits,
                                                                benefit
                                                            );
                                                        }
                                                    }}
                                                    spacer={false}
                                                />

                                                <Button
                                                    button
                                                    children={"Add"}
                                                    disabled={
                                                        values.vacancy.benefit
                                                            ?.length < 1
                                                    }
                                                    onClick={() => {
                                                        addValue(
                                                            "vacancy.benefit",
                                                            benefits,
                                                            benefit
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </section>
                                    {benefits && (
                                        <section>
                                            <p className="text-[16px] text-gray-300 font-thin mb-4">
                                                <strong>Tip:</strong> We've
                                                inserted the benefits from your
                                                Company profile here.
                                            </p>
                                            {benefits.map((benefit, index) => {
                                                return (
                                                    <ListContainer key={index}>
                                                        <ListItem>
                                                            <ListNumber>
                                                                {index + 1}.
                                                            </ListNumber>
                                                            {benefit}
                                                        </ListItem>
                                                        <TrashIcon
                                                            size={size}
                                                            icon={faTrashCan}
                                                            onClick={() =>
                                                                removeValue(
                                                                    "vacancy.benefit",
                                                                    benefits,
                                                                    benefit
                                                                )
                                                            }
                                                        />
                                                    </ListContainer>
                                                );
                                            })}
                                        </section>
                                    )}
                                    <Divider />
                                    <section
                                        className="grid gap-12"
                                        ref={questionsIntersectionRef}
                                        data-tip="Ask your interview questions upfront"
                                    >
                                        <div className="grid gap-4">
                                            <label
                                                htmlFor="addTag"
                                                className="text-white"
                                            >
                                                Questions for Candidates
                                            </label>
                                            <div className="grid gap-6 grid-cols-[1fr_auto] items-stretch">
                                                <FormikTextField
                                                    placeholder="Type your question"
                                                    spacer={false}
                                                    fullWidth
                                                    className="space-x-12"
                                                    name="vacancy.candidateQuestion"
                                                    value={candidateQuestion}
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === "Enter" &&
                                                            values?.vacancy
                                                                ?.candidateQuestion
                                                                ?.length > 0
                                                        ) {
                                                            if (
                                                                candidateQuestions.length >
                                                                1
                                                            ) {
                                                                alert(
                                                                    "You can only add 2 questions"
                                                                );
                                                            } else {
                                                                addValue(
                                                                    "vacancy.candidateQuestion",
                                                                    candidateQuestions,
                                                                    candidateQuestion
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />

                                                <Button
                                                    button
                                                    children={"Save"}
                                                    disabled={
                                                        values?.vacancy
                                                            ?.candidateQuestion
                                                            ?.length < 1
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            candidateQuestions.length >
                                                            1
                                                        ) {
                                                            alert(
                                                                "You can only add 2 questions"
                                                            );
                                                        } else {
                                                            return addValue(
                                                                "vacancy.candidateQuestion",
                                                                candidateQuestions,
                                                                candidateQuestion
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            className="group text-white flex gap-6 flex-row hover:bg-primary hover:text-black rounded-xl justify-self-start"
                                            onClick={() =>
                                                addValue(
                                                    "vacancy.candidateQuestion",
                                                    candidateQuestions,
                                                    candidateQuestion
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                className="w-10 h-10 text-primary group-hover:text-black"
                                            />
                                            Add another question
                                        </button>

                                        {candidateQuestions && (
                                            <div>
                                                {candidateQuestions.map(
                                                    (
                                                        candidateQuestion,
                                                        index
                                                    ) => {
                                                        return (
                                                            <ListContainer
                                                                key={index}
                                                            >
                                                                <ListItem>
                                                                    <ListNumber>
                                                                        Q
                                                                        {index +
                                                                            1}
                                                                        .
                                                                    </ListNumber>
                                                                    {
                                                                        candidateQuestion
                                                                    }
                                                                </ListItem>
                                                                <TrashIcon
                                                                    size={size}
                                                                    icon={
                                                                        faTrashCan
                                                                    }
                                                                    onClick={() =>
                                                                        removeValue(
                                                                            "vacancy.candidateQuestion",
                                                                            candidateQuestions,
                                                                            candidateQuestion
                                                                        )
                                                                    }
                                                                />
                                                            </ListContainer>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </section>
                                    <Divider />
                                    <TitleLabel>
                                        Upload a Business Portfolio
                                    </TitleLabel>
                                    <FileUpload
                                        type={"file"}
                                        cloudFile={
                                            existingVacancy?.portfolio || null
                                        }
                                        setSelectedFile={
                                            selectFileUploadPortfolio
                                        }
                                    />
                                </div>
                            </FormikProvider>
                            <Divider />
                            <div className="grid gap-4">
                                <button
                                    onClick={() =>
                                        setFieldValue(
                                            "vacancy.isPublic",
                                            !values.vacancy.isPublic
                                        )
                                    }
                                    className="flex gap-12 p-8 bg-[#232424] text-white rounded-xl justify-between items-center text-left"
                                >
                                    <p>Publicly visible</p>
                                    <Toggle
                                        checked={values.vacancy.isPublic}
                                        onChange={() =>
                                            setFieldValue(
                                                "vacancy.isPublic",
                                                !values.vacancy.isPublic
                                            )
                                        }
                                        name="Public"
                                        inactiveBackground="black"
                                    />
                                </button>
                                <p className="text-[16px] text-gray-300 font-thin mt-4">
                                    <strong>Tip:</strong> If disabled, this
                                    Vacancy will not be displayed on the Job
                                    Board, nor will it be included in search
                                    results.{" "}
                                    <strong>Applicants can still apply</strong>{" "}
                                    if they have a direct link to the vacancy.
                                </p>
                            </div>
                            <Divider />
                            {!!errors?.vacancy &&
                                Object.values(errors?.vacancy).map(
                                    (error, i) => <Error key={i}>{error}</Error>
                                )}
                            {Object.values(validationInfo).map((error, i) => (
                                <Error key={i}>{error}</Error>
                            ))}
                        </div>
                        <InfoSidebar
                            size={size}
                            style={{ display: size.isMdUp ? "grid" : "none" }}
                        >
                            <div className="wrapper">
                                <VideoCardJobBoard
                                    videoData="https://seesyfiles.s3.eu-west-2.amazonaws.com/create-vacancy-info.mp4"
                                    width={"100%"}
                                    height={"auto"}
                                    action={<></>}
                                    preload="metadata"
                                />
                                <div className="content">
                                    <h2>“{currentTip}”</h2>
                                </div>
                            </div>
                        </InfoSidebar>
                    </ContentWrapper>
                </Container>
                <BottomContainer size={size}>
                    <ButtonsGroup size={size}>
                        {!size.isMdUp && (
                            <Button
                                button
                                size="large"
                                fullWidth={!size.isSmUp}
                                onClick={() => setShowInfoVideo(true)}
                                type={"alt"}
                            >
                                Show Guide Video
                            </Button>
                        )}
                        {!!vacancyId &&
                            isValid &&
                            Object.keys(validationInfo).length === 0 && (
                                <Button
                                    button
                                    size="large"
                                    fullWidth={!size.isSmUp}
                                    onClick={() => onSubmit()}
                                    disabled={!isValid || isUploading}
                                >
                                    Save Changes
                                </Button>
                            )}
                        <Button
                            button
                            size="large"
                            fullWidth={!size.isSmUp}
                            onClick={() => onSubmit(true)}
                        >
                            {!isValid || Object.keys(validationInfo).length > 0
                                ? "Save Progress"
                                : existingVacancy?.videoURL
                                    ? "Edit Video"
                                    : "Next"}
                        </Button>
                    </ButtonsGroup>
                </BottomContainer>
            </div>
            {showInfoVideo && !size.isMdUp && (
                <InfoVideo>
                    <h1>Vacancy Guide Video</h1>
                    <button onClick={() => setShowInfoVideo(false)}>
                        <Icon icon={faClose}></Icon>
                    </button>
                    <VideoCardJobBoard
                        videoData="https://seesyfiles.s3.eu-west-2.amazonaws.com/create-vacancy-info.mp4"
                        width={"100%"}
                        height={"80vh"}
                        action={<></>}
                        aspectRatio="auto"
                        preload="metadata"
                    />
                </InfoVideo>
            )}
        </AuthGuard>
    );
});

const Container = styled.div(
    ({ size }) => `
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-bottom: ${size.isMdUp ? "250px" : "150px"};

  & button {
    padding: 16.5px 25px;
  }
  & hr{
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    margin-bottom: 60px;
  }
  `
);

const Title = styled.h2`
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: ${Colors.white};
    margin-bottom: 3px;
`;

const BottomContainer = styled.div(
    ({ size }) => `
  position: fixed;
  bottom:0;
  padding: 20px;
  padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  background-color: ${Colors.ghostAsh};
  flex-direction:${size.isMdUp ? "row" : "column"};
  align-items: center;
  width: 100%;
  display: flex;
  flex: 1;
  z-index: 3;
  `
);

const ButtonsGroup = styled.div(
    ({ size }) => `
  display: flex;
  flex-direction: ${size.isSmUp ? "row" : "column"};
  gap: 16px;
  width: 100%;
  max-width: var(--max-width);
  justify-content: ${size.isSmUp ? "flex-end" : "stretch"};
  `
);
const ListContainer = styled.div`
    max-width: 710px;
    color: ${Colors.white};
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
`;

const ListItem = styled.div`
    width: 100%;
    padding: 17px 30px;
    background-color: ${Colors.darkBorder};
    flex-direction: row;
    display: flex;
    border-radius: 5px;
`;

const ListNumber = styled(Title)`
    margin: 0px 0px;
    margin-right: 9px;
`;

const TrashIcon = styled(Icon)(
    ({ size }) =>
        `
  margin-left: ${size.isMdUp ? "20px" : "30px"}
  `
);

const Error = styled.p`
    color: red;
    margin-bottom: 8px;
`;

const AIPrompt = styled.div`
    position: relative;
    top: -35px;
    background: #232424;
    padding: 20px;
    color: white;
    display: grid;
    gap: 20px;
    font-size: 16px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // border-left: 1px solid #9400ff;
    // border-bottom: 1px solid #9400ff;
    // border-right: 1px solid #9400ff;
    :after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        top: -10px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background: #232424;
    }
    div {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 10px;
    }
    button {
        font-size: 16px;
        max-width: none;
    }
`;

const InfoSidebar = styled.div`
    --avatar-size: 60px;
    z-index: 2;
    opacity: 1;
    transition: opacity 200ms ease;
    &[data-hide="true"] {
        opacity: 0;
    }
    .wrapper {
        position: sticky;
        top: 20px;
        height: fit-content;
        display: grid;
        gap: 20px;
        right: 0;
        video {
            max-height: 40vh;
        }
        .content {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
            align-items: flex-start;
            img {
                aspect-ratio: 1;
                width: var(--avatar-size);
                height: var(--avatar-size);
                border-radius: var(--avatar-size);
            }
            h2 {
                color: white;
                font-weight: 800;
                font-size: 32px;
                text-transform: uppercase;
                word-break: break-word;
            }
        }
    }
`;

const InfoVideo = styled.div`
    position: fixed;
    display: grid;
    gap: 16px;
    align-content: center;
    justify-items: center;
    width: 100%;
    height: 100vh;
    height: 100svh;
    left: 0;
    top: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(30px);
    z-index: 9999;
    h1 {
        font-size: 24px;
        color: white;
        text-transform: uppercase;
    }
    button {
        color: white;
        position: absolute;
        top: 60px;
        right: 60px;
        font-size: 28px;
    }
`;

const ContentWrapper = styled.div(
    ({ size }) => `
  display: grid;
  gap: 64px;
  grid-template-columns: ${size.isMdUp ? "1fr 400px" : "1fr"};
}`
);

const TitleLabel = styled.div`
    display: block;
    margin-bottom: 10px;
    color: #ffffff;
`;
